<template>
  <div class="content">
    <!-- 搜索框 -->
    <div class="header-item">
      <div class="item-content">
        <label></label>
        <el-input style="display: inline-block; "
          v-model="params.name" placeholder="请输入项目名称/编号" clearable @clear="getList(0)"></el-input>
      </div>
    </div>
    <!-- 搜索按钮 -->
    <div class="header-item">
      <el-button type="primary"  icon="el-icon-search" @click="getList(0)">搜索</el-button>
    </div>
    <div style="float:right;margin-right:10px" >
      <el-button type="primary"  icon="el-icon-plus" @click="goAddPage">新增商机</el-button>
    </div>

    <!--table公司名片表格  -->
    <el-table  tooltip-effect="dark" text-align='center' :data="dataList"  align="center"
    class="tableBox" style="width: 100%; margin-top: 10px"
    header-row-class-name="table-header-style"
    @sort-change="changeTableSort"
    :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
      <el-table-column label="项目编号"  width="140" >
        <template slot-scope="scope">
          {{scope.row.id || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="项目名称" width="300">
        <template slot-scope="scope">
          {{scope.row.projectName || '无'}}
        </template>
      </el-table-column>
      <el-table-column label="行业" >
        <template slot-scope="scope">
          <template v-if="scope.row.industry">
            <div >
              <el-tag class="roles-tag" v-for="(item,index) in backArray(scope.row.industry)" :key="index">{{item}}</el-tag>
            </div>
          </template>
          <template v-else>
            <el-tag>无</el-tag>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="查看人数" align="center"  width="109" prop="viewNumber" :sortable="'custom'" >
          <template slot-scope="scope">
          {{scope.row.viewNumber || 0}}
        </template>
      </el-table-column>
      <el-table-column label="咨询人数" align="center" width="109"  prop="askNumber"  :sortable="'custom'">
          <template slot-scope="scope">
          {{scope.row.askNumber || 0}}
        </template>
      </el-table-column>
      <el-table-column label="操作"  width="100" >
        <template slot-scope="scope">
          <el-button  type="primary" @click="viewDetails(scope.row)" size="mini">查看详情</el-button>
          <div class="viewDetails">
            <el-dialog
              title="商机详情"
              :visible.sync="dialogVisible"
              :close-on-click-modal="false"
              width="375px">
              <template v-if="scope.row.createrId"  >
                <p>招标名称：{{detailsData.projectName}}</p>
                <p>招标行业：{{detailsData.industry}}</p>
                <div class="hr"></div>
                <div class="project-item">
                  <p class="title">项目详情</p>
                  <div class="des">{{detailsData.projectDetails || '暂无'}}</div>
                  <div v-if="Images.projectDetailsImg == ''"></div>
                  <div v-else><img v-for="(item,index) in Images.projectDetailsImg" :key="index" :src="item" alt=""></div>
                </div> 
                <div class="project-item" v-if="detailsData.serviceDescription || Images.serviceDescriptionImg != ''">
                  <p class="title">产品服务</p>
                  <div class="des">{{detailsData.serviceDescription }}</div>
                  <div v-if="Images.serviceDescriptionImg == ''"></div>
                  <div v-else><img v-for="(item,index) in Images.serviceDescriptionImg" :key="index" :src="item" alt=""></div>
                </div>    



                <div class="project-item" v-if="detailsData.teamDescription || Images.teamDescriptionImg != ''">
                  <p class="title" >团队实力</p>
                  <div class="des">{{detailsData.teamDescription }}</div>
                  <div v-if="Images.teamDescriptionImg == ''"></div>
                  <div v-else><img v-for="(item,index) in Images.teamDescriptionImg" :key="index" :src="item" alt=""></div>
                </div>



                <div class="project-item" v-if="detailsData.advantagesOfCooperation || Images.advantagesOfCooperationImg != ''">
                  <p class="title">合作优势</p>
                  <div class="des">{{detailsData.advantagesOfCooperation }}</div>
                   <div v-if="Images.advantagesOfCooperationImg == ''"></div> 
                  <div v-else><img v-for="(item,index) in Images.advantagesOfCooperationImg" :key="index" :src="item" alt=""></div>
                </div>                
              </template>
              <!-- <template v-else> -->
              <template v-else>
                <p>招标名称：{{detailsData.projectName}}</p>
                <p>招标编号：{{detailsData.id}}</p>
                <p>招标行业：{{detailsData.industry}}</p>
                <p>招标单位：{{detailsData.companyName}}</p>
                <p>截止时间：{{detailsData.endTime}}</p>
                <div class="hr"></div>
                <p class="title">招标详情</p>
                <div class="des">{{detailsData.projectDetails || '暂无'}}</div>
              </template>
              
            </el-dialog>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        :background="true"
        @current-change="getList"
        :current-page.sync="params.pageIndex"
        :page-size="params.pageNumber"
        layout="total, prev, pager, next, jumper"
        :total='plazaCardTotal'>
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList:[],
      plazaCardTotal:0,
      params:{
        name:'',
        orderParam:'',
        sort:'',
        flag:'',
        pageNumber:10,
        pageIndex:0
      },
      dialogVisible:false,//是否显示查看详情
      detailsData:{},//详情数据
      Images:{
        serviceDescriptionImg:[],
        advantagesOfCooperationImg:[],
        projectDetailsImg:[],
        teamDescriptionImg:[]
      }
    } 
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    //分割字符串返回数组
    backArray(str) {
      return str.split(',')
    },
    //获取商机中心列表
   async getList(page) {
      if(this.params.orderParam =='') delete this.params.orderParam;
      if(this.params.sort =='') delete this.params.sort;
      if(this.params.flag =='') delete this.params.flag;
      // console.log(page,'==========');
      this.params.pageIndex=page;
      // console.log(this.params);
      let res = await this.$ZGManager.businessList(this.params)
      if (res.status !== 200)return;
      this.dataList = res.data.list;
      this.plazaCardTotal = res.data.total_rows;
    },
    //查看详情
    async viewDetails(row) {
      let res = await this.$ZGManager.businessInfo(row.id);
      if(res.status !==200 ) return
      this.detailsData = res.data;
      if (this.detailsData.serviceDescriptionImg !=null) {
        this.Images.serviceDescriptionImg = this.detailsData.serviceDescriptionImg.split(',');
      }
      if (this.detailsData.teamDescriptionImg !=null) {
        this.Images.teamDescriptionImg = this.detailsData.teamDescriptionImg.split(',');
      }
      if (this.detailsData.advantagesOfCooperationImg !=null) {
        this.Images.advantagesOfCooperationImg = this.detailsData.advantagesOfCooperationImg.split(',');
      }
      if (this.detailsData.projectDetailsImg !=null) {
        this.Images.projectDetailsImg = this.detailsData.projectDetailsImg.split(',');
      }
    console.log(this.detailsData.teamDescription,'image',this.Images.teamDescriptionImg);

      this.dialogVisible = true;
   },
    //升序降序
    changeTableSort(column, prop, order) {
      this.params.orderParam = column.prop;
      if (column.order == 'ascending') {
        this.params.sort  = 'asc';
      }else if(column.order == 'descending') {
        this.params.sort  = 'desc'
      }
      this.getList();
    },
    //点击跳转增加商机page
    goAddPage() {
      let jumpPage = this.$router.resolve({
        path:'/opportunity/add'
      });
      window.open(jumpPage.href,'_blank')
    },

  }
}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 50px;
  font-size: 14px;
   .pagination {
    float: right;
    margin-top: 20px;
  }
  .breadcrumb {
    padding-bottom: 20px;
    span {
      &:nth-child(1) {
        cursor: pointer;
      }
      &:nth-child(1):hover {
       color: #409EFF;
      }
    }
  }

  .viewDetails {
    p {
     
      font-size: 14px;
      line-height: 20px;
      color: #999999;
      padding-bottom: 19px;
    }
    .hr {
      width: 100%;
      height: 5px;
      background-color: #FAFAFA;
    }
    .project-item {
      margin-top: 15px;
      margin-bottom: 30px;
      img {
        display: inline-block;
        margin:10px  15px 0 0;
        height: 82px;
        width: 82px;
        border-radius: 5px;
      }
    }
    .title {
      color: #000000;
      font-weight: 700;
      padding-bottom: 10px;
    }
    .des {
      font-size: 12px;
      line-height: 20px;
    }
   
  }

.header-item{
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}
.roles-tag{
  margin-right: 5px;
  // margin-bottom: 5px;
}

}


 ::v-deep .el-dialog {
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 1px 3px rgba(216, 211, 211, 0.05);
    .el-dialog__body {
    div {
      img {
        max-width: 360px;
      }
    }
}
  }



</style>